<template>
  <container ref="brand" @error="init">
    <!-- 顶部 -->
    <publicHeader></publicHeader>

    <!-- 横幅 -->
    <publicBanner :src="banner"></publicBanner>

    <!-- 面包屑导航 -->
    <breadcrumb :router="['首页', '品牌介绍']"></breadcrumb>

    <!-- 品牌 -->
    <div class="brand" v-html="brand"></div>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicBanner from "@/components/publicBanner.vue";
import contact from "@/components/contact.vue";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "brand",
  components: {
    publicHeader,
    publicFooter,
    publicBanner,
    breadcrumb,
    contact,
  },
  data() {
    return {
      brand: "",
      banner: "",
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.brand.status("init", {
          height: "100vh",
        });

        // 获取数据
        const brand = await this.$axios("/api/mainstation/brandIntroduction");

        // 设置数据
        this.banner = brand.data.headimage;
        this.brand = brand.data.content;

        // 设置状态
        this.$refs.brand.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.brand.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .brand {
    width: 1200px;
    margin: 90px auto;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .brand {
    width: 100%;
    margin: rpx(40) auto;
  }
}
</style>
